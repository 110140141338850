import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { isSpeechRecognizeState } from "../../store/selectors/speech_recognize";
import SpeechRecognize from "../../models/SpeechRecognize";
import {
  onresultFunctionGet,
  onaudioendFunctionGet,
} from "../../common/speech_recognition_common";
import { speechRecognizeState } from "../../store/atoms/speech_recognize";
import { speechTextState } from "../../store/atoms/speech_text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { FormControlLabel, Switch } from "@mui/material";
import styled from "@emotion/styled";
import { MOB_MAX_WIDTH } from "../../common/constants";

function MainHeader({ diktafontNavigationActToggle }) {
  const isSpeechRecognize = useRecoilValue(isSpeechRecognizeState);
  const [speechRecognize, setSpeechRecognize] =
    useRecoilState(speechRecognizeState);
  const setSpeechText = useSetRecoilState(speechTextState);
  const [switchLabel, setSwitchLabel] = useState("");
  useEffect(() => {
    const recognSwitchLabel = !isSpeechRecognize ? "START" : "STOP";
    setSwitchLabel(recognSwitchLabel);
  }, [isSpeechRecognize]);

  const actionSwitchChangeHandler = (event) => {
    const recognitionActive = event.target.checked;
    if (!recognitionActive) {
      if (speechRecognize !== null) {
        speechRecognize.stopRecognition();
      }
      setSpeechRecognize(null);
    } else {
      const onresult = onresultFunctionGet(setSpeechText);
      const onaudioend = onaudioendFunctionGet(setSpeechRecognize);
      const speechRecogn = new SpeechRecognize({ onresult, onaudioend });
      setSpeechRecognize(speechRecogn);
    }
  };

  return (
    <DivMainHeader>
      <h2>DIKTAFONT</h2>
      <div>
        <FormControlLabel
          control={
            <Switch
              onChange={actionSwitchChangeHandler}
              checked={isSpeechRecognize}
            />
          }
          label={switchLabel}
        />
      </div>
      <DivMainMenuNav>
        <IconButton aria-label="Example" onClick={diktafontNavigationActToggle}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </IconButton>
      </DivMainMenuNav>
    </DivMainHeader>
  );
}

const DivMainMenuNav = styled.div`
  display: none;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: inline;
  }
`;

const DivMainHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export default MainHeader;
