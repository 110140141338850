export const CIR_LAT = new Map([
    ["А", "A"],
    ["Б", "B"],
    ["В", "V"],
    ["Г", "G"],
    ["Д", "D"],
    ["Ђ", "Đ"],
    ["Е", "E"],
    ["Ж", "Ž"],
    ["З", "Z"],
    ["И", "I"],
    ["Ј", "J"],
    ["К", "K"],
    ["Л", "L"],
    ["Љ", "Lj"],
    ["М", "M"],
    ["Н", "N"],
    ["Њ", "Nj"],
    ["О", "O"],
    ["П", "P"],
    ["Р", "R"],
    ["С", "S"],
    ["Т", "T"],
    ["Ћ", "Ć"],
    ["У", "U"],
    ["Ф", "F"],
    ["Х", "H"],
    ["Ц", "C"],
    ["Ч", "Č"],
    ["Џ", "DŽ"],
    ["Ш", "Š"],
    ["а", "a"],
    ["б", "b"],
    ["в", "v"],
    ["г", "g"],
    ["д", "d"],
    ["ђ", "đ"],
    ["е", "e"],
    ["ж", "ž"],
    ["з", "z"],
    ["и", "i"],
    ["ј", "j"],
    ["к", "k"],
    ["л", "l"],
    ["љ", "lj"],
    ["м", "m"],
    ["н", "n"],
    ["њ", "nj"],
    ["о", "o"],
    ["п", "p"],
    ["р", "r"],
    ["с", "s"],
    ["т", "t"],
    ["ћ", "ć"],
    ["у", "u"],
    ["ф", "f"],
    ["х", "h"],
    ["ц", "c"],
    ["ч", "č"],
    ["џ", "dž"],
    ["ш", "š"],
  ]);
  
  export const LAT_CIR = new Map([
    ["DŽ", "Џ"],
    ["Dž", "Џ"],
    ["DJ", "Ђ"],
    ["LJ", "Љ"],
    ["Lj", "Љ"],
    ["NJ", "Њ"],
    ["Nj", "Њ"],
    ["dž", "џ"],
    ["dj", "ђ"],
    ["lj", "љ"],
    ["nj", "њ"],
    ["A", "А"],
    ["B", "Б"],
    ["C", "Ц"],
    ["Č", "Ч"],
    ["Ć", "Ћ"],
    ["D", "Д"],
    ["Đ", "Ђ"],
    ["E", "Е"],
    ["F", "Ф"],
    ["G", "Г"],
    ["H", "Х"],
    ["I", "И"],
    ["J", "Ј"],
    ["K", "К"],
    ["L", "Л"],
    ["M", "М"],
    ["N", "Н"],
    ["O", "О"],
    ["P", "П"],
    ["R", "Р"],
    ["S", "С"],
    ["Š", "Ш"],
    ["T", "Т"],
    ["U", "У"],
    ["V", "В"],
    ["Z", "З"],
    ["Ž", "Ж"],
    ["a", "а"],
    ["b", "б"],
    ["c", "ц"],
    ["č", "ч"],
    ["ć", "ћ"],
    ["d", "д"],
    ["đ", "ђ"],
    ["e", "е"],
    ["f", "ф"],
    ["g", "г"],
    ["h", "х"],
    ["i", "и"],
    ["j", "ј"],
    ["k", "к"],
    ["l", "л"],
    ["m", "м"],
    ["n", "н"],
    ["o", "о"],
    ["p", "п"],
    ["r", "р"],
    ["s", "с"],
    ["š", "ш"],
    ["t", "т"],
    ["u", "у"],
    ["v", "в"],
    ["z", "з"],
    ["ž", "ж"],
  ]);
  
  export const LAT_EN_LAT = {
    DŽ: "DZ",
    Dž: "Dz",
    dž: "dz",
    Č: "C",
    Ć: "C",
    Đ: "dj",
    Š: "S",
    Ž: "Z",
    č: "c",
    ć: "c",
    đ: "dj",
    š: "s",
    ž: "z",
  };
  