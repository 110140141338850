/* eslint-disable react/prop-types */
import React from "react";
import "./Navigation.css";
import NavBasic from "./NavBasic";
import NavLang from "./NavLang";
import NavActionEdit from "./NavActionEdit";

const Navigation = () => {
  return (
    <div className="navigation-main">
      <NavBasic />
      <NavLang />
      <NavActionEdit />
    </div>
  );
};

export default Navigation;
