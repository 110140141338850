import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import "./Navigation.css";
import Button from "@mui/material/Button";
import { textLangState } from "../../store/atoms/text_lang";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { convertLangDefaultGet } from "../../common/lang_conversion";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import { textLangConvert } from "../../common/lang_conversion";

const NavLang = () => {
  const [textLang, setTextLangState] = useRecoilState(textLangState);
  // eslint-disable-next-line
  const [convertDefaultLang, setConvertDefaultLang] = useState(
    convertLangDefaultGet(textLang)
  );
  const [recognitionText, setRecognitionText] =
    useRecoilState(recognitionTextState);
  const convertLangRef = useRef();

  const handleLangChange = (ev) => {
    const textLangValue = ev.target.value;
    console.log("handleLangChange", textLangValue);
    setTextLangState(textLangValue);
  };

  const convertClickHandle = () => {
    const convertLang = convertLangRef.current.value;
    let textConverted = textLangConvert(recognitionText, convertLang);
    if (textConverted !== recognitionText) {
      setRecognitionText(textConverted);
    }
    console.log("convertClickHandle", convertLang);
  };

  return (
    <>
      <div className="navigation-main-group">
        <FormControl fullWidth>
          <InputLabel id="text_lang_label">Language:</InputLabel>
          <Select
            labelId="text_lang_label"
            id="text_lang"
            value={textLang}
            label="Language"
            onChange={handleLangChange}
          >
            <MenuItem value="cir">cir</MenuItem>
            <MenuItem value="lat">lat</MenuItem>
            <MenuItem value="en-small">small en</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="navigation-main-group">
        <FormControl fullWidth>
          <InputLabel id="text_convert_label">Convert to:</InputLabel>
          <Select
            labelId="text_convert_label"
            id="text_convert"
            defaultValue={convertDefaultLang}
            label="Convert"
            inputRef={convertLangRef}
            sx={{ marginTop: "0.5rem" }}
          >
            <MenuItem value="cir">cir</MenuItem>
            <MenuItem value="lat">lat</MenuItem>
            <MenuItem value="en">en</MenuItem>
            <MenuItem value="small en">small en</MenuItem>
          </Select>
          <Button
            variant="outlined"
            onClick={convertClickHandle}
            sx={{ marginTop: "0.5rem", width: "2rem" }}
          >
            OK
          </Button>
        </FormControl>
      </div>
    </>
  );
};

export default NavLang;
