import * as React from "react";
import { useRecoilState } from "recoil";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { actionNotifyTextState } from "../../store/atoms/action_notify_text";

const ActionNotify = () => {
  const [actionNotifyText, setActionNotifyText] = useRecoilState(
    actionNotifyTextState
  );
  setTimeout(() => {
    if (actionNotifyText !== "") {
      setActionNotifyText("");
    }
  }, 5 * 1000);

  console.log("ActionNotify", actionNotifyText);

  return (
    actionNotifyText !== "" && (
      <Box sx={{ width: "100%" }}>
        <Collapse in={true}>
          <Alert sx={{ mb: 2 }}>{actionNotifyText}</Alert>
        </Collapse>
      </Box>
    )
  );
};

export default ActionNotify;
