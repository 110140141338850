/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { actionEditState } from "../../store/atoms/action_edit";
import { Button, TextField } from "@mui/material";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import { ACTION_EDIT_DEFAULT } from "../../common/constants";
import styled from "@emotion/styled";

const TextEdit = () => {
  const setActionEditState = useSetRecoilState(actionEditState);
  const [recognitionText, setRecognitionText] =
    useRecoilState(recognitionTextState);
  const editTextRef = useRef();

  const okClickHandle = () => {
    const text = editTextRef.current.value;
    console.log("okClickHandle", text);
    setActionEditState({ ...ACTION_EDIT_DEFAULT, textEdit: false });
    setRecognitionText(text);
  };

  const cancelClickHandle = () => {
    setActionEditState({ ...ACTION_EDIT_DEFAULT, textEdit: false });
  };

  let content = (
    <Div>
      <TextField
        multiline
        defaultValue={recognitionText}
        sx={{ height: "100%" }}
        fullWidth
        height="100%"
        minRows={20}
        inputRef={editTextRef}
      />
      <Button
        variant="outlined"
        onClick={okClickHandle}
        sx={{ marginLeft: "0rem", marginTop: "1rem", marginBottom: "1rem" }}
      >
        OK
      </Button>
      <Button
        variant="secondary"
        onClick={cancelClickHandle}
        sx={{ marginLeft: "1rem", marginTop: "1rem", marginBottom: "1rem" }}
      >
        Cancel
      </Button>
    </Div>
  );

  return content;
};

const Div = styled.div`
  min-height: 100%;
  div {
    min-height: 100%;
  }
`;

export default TextEdit;
