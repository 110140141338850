import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "./Navigation.css";
import Button from "@mui/material/Button";
import { FormControl } from "@mui/material";
import { actionEditState } from "../../store/atoms/action_edit";
import { ACTION_EDIT_DEFAULT } from "../../common/constants";
import { isTextEditState } from "../../store/selectors/action_edit";

const NavEdit = () => {
  const setActionEdit = useSetRecoilState(actionEditState);
  const isTextEdit = useRecoilValue(isTextEditState);
  const [editLabel, setEditLabel] = useState('');
  useEffect(() => setEditLabel(!isTextEdit ? 'EDIT' : 'CANCEL'), [isTextEdit]);

  const editClickHandle = () => {
    const textEdit = !isTextEdit ? true : false;
    setActionEdit({ ...ACTION_EDIT_DEFAULT, textEdit }); 
  };

  return (
    <div className="navigation-main-group">
        <FormControl fullWidth>
        <Button
            variant={!isTextEdit ? 'outlined' : 'secondary'}
            onClick={editClickHandle}
            sx={{ width: "2rem" }}
        >
            {editLabel}
        </Button>
        </FormControl>
    </div>
  );
};

export default NavEdit;
