import React, { useEffect, useState } from "react";
import { useIndexedDB } from "react-indexed-db";
import NoteItem from "./NoteItem";
import RecognizeNote from "../../models/RecognizeNote";

function NotesList() {
  const db = useIndexedDB("notes");
  const [notesList, setNotesList] = useState([]);
  useEffect(() => {
    RecognizeNote.getAll(db).then((notes) => {
      setNotesList(notes);
      console.log("notes total", notes.length);
    });
  }, []);
  const list = notesList.map((note, ind) => <NoteItem note={note} key={ind} />);
  return list;
}

export default NotesList;
