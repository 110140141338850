import { selector } from "recoil";
import { actionEditState } from "../atoms/action_edit";

export const isTextEditState = selector({
  key: "isTextEditState",
  get: ({ get }) => {
    const actionEdit = get(actionEditState);
    return actionEdit.textEdit;
  },
});
