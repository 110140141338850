export const ACTION_EDIT_DEFAULT = {
  actionInsert: false,
  actionType: "",
  textEdit: false,
};

export const MAX_TOTAL_SPEECH_RECOGNIZE = 100;

export const ACTION_EDIT_ACTIVATION = "edit";
export const EDIT_ACTIONS = ["tacka", "novi red", "cirilica", "latinica"];
// export const

export const MOB_MAX_WIDTH = '600px';
export const COLOR_CURRENT = '#555';