import { obDefaultValGet } from "../common/common";

export default class RecognizeNote {
  constructor(item = {}) {
    this.id = obDefaultValGet(item, "id", "");
    this.name = obDefaultValGet(item, "name", "");
    this.text = obDefaultValGet(item, "text", "");
    const dateTimeS = obDefaultValGet(item, "dateTime", new Date());
    this.dateTime = new Date(dateTimeS);
  }

  async save(db) {
    let res = null;
    if (this.id === "") {
      res = await db.add({
        name: this.name,
        text: this.text,
        dateTime: this.dateTime.toString(),
      });
    } else {
      res = await db.update({
        id: this.id,
        name: this.name,
        text: this.text,
        dateTime: this.dateTime.toString(),
      });
    }
    return res;
  }

  static async getAll(db) {
    let items = await db.getAll();
    items = items.map((item) => new RecognizeNote(item, db));
    return items;
  }

  static async delete(db, id) {
    let res = await db.deleteRecord(id);
    return res;
  }

  static async getByID(db, id) {
    let res = await db.getByID(id);
    res = new RecognizeNote(res, db);
    return res;
  }

  static async getMaxID(db) {
    const notes = await this.getAll(db);
    const res = notes.reduce((prevMaxId, currentNote) => {
      let res = prevMaxId;
      const currentId = currentNote.id;
      if (currentId !== "" && currentId > prevMaxId) {
        res = currentId;
      }
      return res;
    }, 0);
    return res;
  }

  shortText() {
    const maxLength = 5;
    let res = this.text;
    if (res.length > maxLength) {
      res = this.text.substring(0, maxLength);
      res = res.substring(0, Math.min(res.length, res.lastIndexOf(" ")));
    }
    return res;
  }

  nameDateTime() {
    let res = "";
    if (this.name !== "") {
      res += this.name;
    }
    if (this.dateTime !== "") {
      res += (res !== "" ? ", " : "") + this.dateTime.toLocaleDateString();
    }
    return res;
  }
}
