import React from "react";
import { useSetRecoilState } from "recoil";
import { useIndexedDB } from "react-indexed-db";
import { actionNotifyTextState } from "../../store/atoms/action_notify_text";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import { viewTypeState } from "../../store/atoms/view_type";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

function NoteItem({ note }) {
  const db = useIndexedDB("notes");
  const setActionNotifyText = useSetRecoilState(actionNotifyTextState);
  const setViewType = useSetRecoilState(viewTypeState);
  const setRecognitionText = useSetRecoilState(recognitionTextState);

  const clickNoteHandle = () => {
    console.log("note", note);
  };
  const editHandle = () => {
    setViewType({ type: "single", note });
    setRecognitionText(note.text);
  };
  const deleteHandle = () => {
    db.deleteRecord(note.id).then(() => {
      setActionNotifyText("Note deleted");
    });
  };

  const item = (
    <Card sx={{ padding: "1.5rem", marginTop: "1.5rem" }}>
      <Typography
        variant="h5"
        sx={{ cursor: "pointer" }}
        onClick={clickNoteHandle}
      >
        <span onClick={editHandle}>{note.name}</span>,
        <span style={{ marginLeft: "1rem", fontSize: "1rem" }}>
          {note.dateTime.toLocaleDateString()}
        </span>
      </Typography>
      <CardContent>{note.text}</CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={editHandle}>Edit</Button>
        <Button onClick={deleteHandle}>Delete</Button>
      </CardActions>
    </Card>
  );
  return item;
}

export default NoteItem;
