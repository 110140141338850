import React from "react";
import { useRecoilState } from "recoil";
import { noteSaveState } from "../../store/atoms/note_save";
import Button from "@mui/material/Button";

const NoteSaveActivation = () => {
  const [noteSave, setNoteSave] = useRecoilState(noteSaveState);

  const saveRecordingClickHandle = () => {
    setNoteSave(true);
  };

  let content = null;
  if (!noteSave) {
    content = (
      <Button variant="outlined" onClick={saveRecordingClickHandle}>
        SAVE
      </Button>
    );
  }

  return content;

};

export default NoteSaveActivation;
