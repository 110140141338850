import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { viewTypeState } from "../../store/atoms/view_type";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import { noteSaveState } from "../../store/atoms/note_save";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import RecognizeNote from "../../models/RecognizeNote";
import NoteSave from "../recognition/NoteSave";
import { MOB_MAX_WIDTH } from "../../common/constants";
import NoteCurrent from "../layout/NoteCurrent";
import NoteSaveActivation from "./NoteSaveActivation";

const NavActive = () => {
  const setViewType = useSetRecoilState(viewTypeState);
  const setRecognitionText = useSetRecoilState(recognitionTextState);
  const noteSave = useRecoilValue(noteSaveState);

  const newClickHandle = () => {
    setViewType({ type: "single", note: new RecognizeNote() });
    setRecognitionText("");
  };

  const listClickHandle = () => {
    setViewType({ type: "list", note: new RecognizeNote() });
    setRecognitionText("");
  };

  let viewTypeNav = null;
  if (!noteSave) {
    viewTypeNav = (
      <div>
        <Button variant="outlined" onClick={newClickHandle}>
          NEW
        </Button>
        <Button
          variant="outlined"
          onClick={listClickHandle}
          sx={{ marginLeft: "1rem" }}
        >
          LIST
        </Button>
      </div>
    );
  }

  const content = (
    <div>
      <Div>
        <div style={{display: 'flex'}}>
          <NoteSave />
          <DivDesCurrentNote>
            <NoteCurrent />
          </DivDesCurrentNote>
          <NoteSaveActivation />
        </div>
        {viewTypeNav}
      </Div>
      <DivMobCurrentNote>
        <NoteCurrent />
      </DivMobCurrentNote>
    </div>
  );

  return content;
};

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const DivMobCurrentNote = styled.div`
  display: none;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: block;
  }
`;

const DivDesCurrentNote = styled.div`
  align-self: center;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: none;
  }
`;

export default NavActive;
