import { selector } from "recoil";
import { speechRecognizeState } from "../atoms/speech_recognize";

export const isSpeechRecognizeState = selector({
  key: "isSpeechRecognizeState",
  get: ({ get }) => {
    const speechRecognize = get(speechRecognizeState);
    let active = false;
    if (speechRecognize !== null) {
      active = true;
    }
    return active;
  },
});
