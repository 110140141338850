/*eslint-disable react-hooks/exhaustive-deps*/
import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ACTION_EDIT_DEFAULT } from "../../common/constants";
import { actionEditState } from "../../store/atoms/action_edit";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import {
  recognitonTextGet,
  activationActionGet,
} from "../../common/speech_recognition_common";
import { textLangState } from "../../store/atoms/text_lang";
import { actionAct } from "../../common/text_actions";
import { actionNotifyTextState } from "../../store/atoms/action_notify_text";
import { speechTextState } from "../../store/atoms/speech_text";

function RecognitionProcessing() {
  const [actionEdit, setActionEdit] = useRecoilState(actionEditState);
  const speechText = useRecoilValue(speechTextState);
  const [recognitionText, setRecognitionText] =
    useRecoilState(recognitionTextState);
  const [textLang, setTextLang] = useRecoilState(textLangState);
  const setActionNotifyText = useSetRecoilState(actionNotifyTextState);
  useEffect(() => {
    const speechTxt = speechText.text;
    if (speechTxt !== "") {
      const { activation, action } = activationActionGet(
        speechTxt,
        actionEdit.actionInsert
      );
      console.log("speechTxt", speechTxt);
      if (activation) {
        if (action === "") {
          console.log("actionInsert");
          setActionEdit({ ...ACTION_EDIT_DEFAULT, actionInsert: true });
        } else {
          actionAct(action, {
            recognitionText,
            setRecognitionText,
            textLang,
            setTextLang,
            setActionEdit,
            setActionNotifyText,
          });
        }
      } else {
        setRecognitionText((currentValue) => {
          let res =
            currentValue +
            (currentValue !== "" ? " " : "") +
            recognitonTextGet(speechTxt, textLang, currentValue);
          return res;
        });
      }
    }
  }, [speechText]);

  return null;
}

export default RecognitionProcessing;
