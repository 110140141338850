import React from "react";
import { useRecoilValue } from "recoil";
import { viewTypeState } from "../../store/atoms/view_type";
import { noteSaveState } from "../../store/atoms/note_save";
import styled from "@emotion/styled";
import { MOB_MAX_WIDTH, COLOR_CURRENT } from "../../common/constants";

const NoteCurrent = () => {
  const viewType = useRecoilValue(viewTypeState);
  const noteSave = useRecoilValue(noteSaveState);

  let content = null;
  const note = viewType.note;
  if (note.id !== "" && !noteSave) {
    content = (
      <DivCurrentNote>
        {note.name}
      </DivCurrentNote>
    );
  }
  return content;

};

const DivCurrentNote = styled.div`
  margin-right: 1rem;
  align-self: center;
  @media (max-width: ${MOB_MAX_WIDTH}) {
    margin-bottom: 0.5rem;
    color: ${COLOR_CURRENT}
  }
`;

export default NoteCurrent;
