/* eslint-disable react/prop-types */
import React from "react";
import { useRecoilValue } from "recoil";
import { Container } from "@mui/system";
import { isSingleViewState } from "../../store/selectors/view_type";
import ActionActive from "../recognition/ActionActive";
import NotesList from "./NotesList";
import RecognitionEdit from "../recognition/RecognitionEdit";
import NavActive from "../navigation/NavActive";

const MainContent = () => {
  const isSingleView = useRecoilValue(isSingleViewState);

  let content = null;
  if (isSingleView) {
    content = <RecognitionEdit />;
  } else {
    content = <NotesList />;
  }

  content = (
    <div>
      <NavActive />
      <Container
        sx={{
          boxShadow: "2px 2px 2px #888888",
          minHeight: "35rem",
          backgroundColor: "rgba(200, 200, 200, 0.1)",
          paddingTop: "0.8rem",
          paddingBottom: "0.8rem",
          position: "relative",
        }}
      >
        <ActionActive />
        {content}
      </Container>
    </div>
  );

  return content;
};

export default MainContent;
