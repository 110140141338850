import { CIR_LAT, LAT_CIR, LAT_EN_LAT } from './lang_contants';

/**
 * conv to ser cyrilic
 */
export const toCir = (s1) => {
  let res = s1;
  let pat;

  LAT_CIR.forEach((value, key) => {
    pat = new RegExp(key, "g");
    res = res.replace(pat, value);
  });

  return res;
};

/**
 * conv to ser latin
 */
export const toLat = (s1) => {
  let res = s1;
  let pat;

  CIR_LAT.forEach((value, key) => {
    pat = new RegExp(key, "g");
    res = res.replace(pat, value);
  });

  return res;
};

/**
 * conv to letters of english alfabet
 */
export const toEnLat = (s1) => {
  let res = s1;
  let pat;

  res = toLat(res);

  for (let key in LAT_EN_LAT) {
    if (LAT_EN_LAT.hasOwnProperty(key)) {
      pat = new RegExp(key, "g");
      res = res.replace(pat, LAT_EN_LAT[key]);
    }
  }

  return res;
};

/**
 * conv to small letters of english alfabet
 */
export const toEnLatSmall = (s1) => {
  let res = s1;

  res = toEnLat(res);
  res = res.toLowerCase();

  return res;
};

export const convertLangDefaultGet = (lang) => {
  let res = "cir";
  if (lang === "lat") {
    res = "cir";
  } else if (lang === "cir") {
    res = "lat";
  }
  return res;
};

export const langTextGet = (text, lang = "lat") => {
  let res = text;
  if (lang === "lat") {
    res = toLat(res);
  } else if (lang === "cir") {
    res = toCir(res);
  } else if (lang === "en-small") {
    res = toEnLatSmall(res);
  }
  return res;
};

export const textLangConvert = (text, convertLang) => {
  let textConverted = text;
  if (convertLang === "cir") {
    textConverted = toCir(textConverted);
  } else if (convertLang === "lat") {
    textConverted = toLat(textConverted);
  } else if (convertLang === "en") {
    textConverted = toEnLat(textConverted);
  } else if (convertLang === "small en") {
    textConverted = toEnLatSmall(textConverted);
  }

  return textConverted;
}
