/* eslint-disable react/prop-types */
import React from "react";
import { useRecoilValue } from "recoil";
import { isSpeechRecognizeState } from "../../store/selectors/speech_recognize";
import styled from "@emotion/styled";

const RecognitionText = ({ recognitionText }) => {
  const isSpeechRecognize = useRecoilValue(isSpeechRecognizeState);
  const recognTextN = recognitionText.split("\n");
  let content = recognTextN.map((value, ind) => {
    const separator = ind < recognTextN.length - 1 ? <br /> : null;
    return (
      <React.Fragment key={ind}>
        {value}
        {separator}
      </React.Fragment>
    );
  });

  const Span = isSpeechRecognize ? styled.span`
    @keyframes action_active {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    color: green;
    animation-name: action_active;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  ` : styled.span``;

  content = (
    <React.Fragment>
      {content}
      <Span>
        _
      </Span>
    </React.Fragment>
  );

  return content;
};


export default RecognitionText;
