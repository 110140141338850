/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { noteSaveState } from "../../store/atoms/note_save";
import { actionNotifyTextState } from "../../store/atoms/action_notify_text";
import { Button, TextField } from "@mui/material";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import { viewTypeState } from "../../store/atoms/view_type";
import { noteIdState } from "../../store/selectors/view_type";
import RecognizeNote from "../../models/RecognizeNote";
import { useIndexedDB } from "react-indexed-db";
import styled from "@emotion/styled";

const NoteSave = () => {
  const db = useIndexedDB("notes");
  const recognitionText = useRecoilValue(recognitionTextState);
  const [viewType, setViewType] = useRecoilState(viewTypeState);
  const [noteSave, setNoteSave] = useRecoilState(noteSaveState);
  const setActionNotifyText = useSetRecoilState(actionNotifyTextState);
  const noteId = useRecoilValue(noteIdState);
  const nameRef = useRef();
  useEffect(() => {
    if (
      viewType.note.id === "" &&
      typeof nameRef.current !== "undefined" &&
      nameRef.current !== null
    ) {
      nameRef.current.value = "";
    }
  }, [viewType]);

  const okClickHandle = () => {
    const name = nameRef.current.value;
    if (recognitionText !== "") {
      setNoteSave(false);
      const dateTime = noteId === "" ? new Date() : viewType.note.dateTime;
      const note = new RecognizeNote({
        id: noteId,
        name,
        text: recognitionText,
        dateTime,
      });
      note.save(db).then(() => {
        const saveNotification =
          noteId === "" ? "New note saved." : "Note saved.";
        setActionNotifyText(saveNotification);
        if (noteId === "") {
          RecognizeNote.getMaxID(db).then((notesMaxId) => {
            RecognizeNote.getByID(db, notesMaxId).then((note) => {
              setViewType({ type: "single", note });
            });
          });
        }
      });
    } else {
      console.log("Text must not be empty");
    }
  };

  const cancelClickHandle = () => {
    setNoteSave(false);
  };

  let content = null;

  if (noteSave) {
    content = (
      <DivNoteSave>
        <TextField inputRef={nameRef} defaultValue={viewType.note.name} />
        <Button variant="outlined" onClick={okClickHandle}>
          OK
        </Button>
        <Button variant="secondary" onClick={cancelClickHandle}>
          Cancel
        </Button>
      </DivNoteSave>
    );
  }

  return content;
};

const DivNoteSave = styled.div`
  input[type=text] {
    height: 1.5rem;
    margin-top: 0;
    padding-top: 0.5rem;
  }

  .MuiOutlinedInput-notchedOutline {
    height: 2.5rem;
  }

  button {
    margin-left: 1rem;
  }

  @media (max-width: 600px) {
    button {
        margin-left: 0;
    }
    
    .MuiFormControl-root {
        width: 100%;
    }
  }
`

export default NoteSave;
