import { ACTION_EDIT_DEFAULT } from "./constants";
import { toCir } from "./lang_conversion";
import { copyToClipboard } from "./common";

export const actionTextGet = (action, textLang = "lat") => {
  let res = "";

  if (action === "tacka") {
    res = ".";
  } else if (action === "zarez") {
    res = ",";
  } else if (action === "novi red") {
    res = ".\n";
  } else if (action === "insert") {
    res = " insert";
    if (textLang === "cir") {
      res = toCir(res);
    }
  }

  return res;
};

export const actionAct = (
  action,
  {
    recognitionText,
    setRecognitionText,
    textLang,
    setTextLang,
    setActionEdit,
    setActionNotifyText,
  }
) => {
  if (action === "stop") {
    setActionEdit({ ...ACTION_EDIT_DEFAULT, actionInsert: false });
  } else {
    let actionReset = false;
    const actionText = actionTextGet(action, textLang);
    console.log("actionText", actionText);
    if (actionText !== "") {
      const recognText = recognitionText + actionText;
      setRecognitionText(recognText);
      actionReset = true;
    } else {
      console.log("actEdit", action);
      if (action === "cirilica") {
        if (textLang !== "cir") {
          setTextLang("cir");
        }
      } else if (action === "latinica") {
        if (textLang !== "lat") {
          setTextLang("lat");
        }
      } else if (action === "kopiraj") {
        copyToClipboard(recognitionText + "");
        setActionNotifyText("Coppied to clipboard");
      }
      if (["cirilica", "latinica", "kopiraj"].includes(action)) {
        actionReset = true;
      }
    }
    if (actionReset) {
      setActionEdit({ ...ACTION_EDIT_DEFAULT, actionInsert: false });
    }
  }
};
