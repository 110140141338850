/* eslint-disable react/prop-types */
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import "./Navigation.css";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { actionEditState } from "../../store/atoms/action_edit";
import { ACTION_EDIT_DEFAULT } from "../../common/constants";
import { actionAct } from "../../common/text_actions";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import { textLangState } from "../../store/atoms/text_lang";
import { actionNotifyTextState } from "../../store/atoms/action_notify_text";
import styled from "@emotion/styled";
import { MOB_MAX_WIDTH } from "../../common/constants";
import NavEdit from "./NavEdit";

const NavActionEdit = () => {
  const [actionEdit, setActionEdit] = useRecoilState(actionEditState);
  const [textLang, setTextLang] = useRecoilState(textLangState);
  const [recognitionText, setRecognitionText] =
    useRecoilState(recognitionTextState);
  const setActionNotifyText = useSetRecoilState(actionNotifyTextState);

  const actEdit = (action) => {
    actionAct(action, {
      recognitionText,
      setRecognitionText,
      textLang,
      setTextLang,
      setActionEdit,
      setActionNotifyText,
    });
    console.log("actionChangeHandler");
  };

  const actionClickHandler = (event) => {
    const action = event.target.getAttribute("data-value");
    console.log("actionClickHandler", action);
    actEdit(action);
  };

  const actionSwitchChangeHandler = (event) => {
    const actionInsert = event.target.checked;
    setActionEdit({ ...ACTION_EDIT_DEFAULT, actionInsert });
    console.log("actionSwitchChangeHandler", actionInsert);
  };

  return (
    <>
      <div className="navigation-main-group">
        <FormControl fullWidth>
          <Div>
            <FormControlLabel
              control={
                <Switch
                  onChange={actionSwitchChangeHandler}
                  checked={actionEdit.actionInsert}
                />
              }
              label="Action"
            />
          </Div>
          <Select
            id="input_action"
            defaultValue=""
            onClick={actionClickHandler}
          >
            <MenuItem value="stop">stop</MenuItem>
            <MenuItem value="tacka">tacka</MenuItem>
            <MenuItem value="zarez">zarez</MenuItem>
            <MenuItem value="novi red">novi red</MenuItem>
            <MenuItem value="cirilica">cirilica</MenuItem>
            <MenuItem value="latinica">latinica</MenuItem>
          </Select>
        </FormControl>
      </div>
      <NavEdit />
    </>
  );
};

const Div = styled.div`
  @media (max-width: ${MOB_MAX_WIDTH}) {
    display: none;
  }
`;

export default NavActionEdit;
