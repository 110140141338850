import { obDefaultValGet } from "../common/common";

export default class SpeechRecognize {
  constructor(options) {
    const lang = obDefaultValGet(options, "lang", "sr-RS");
    const onresult = obDefaultValGet(options, "onresult", null);
    const onaudioend = obDefaultValGet(options, "onaudioend", () => {
      console.log("Audio capturing ended");
    });
    let SpeechRecognition =
      window.webkitSpeechRecognition || window.SpeechRecognition;
    if (typeof SpeechRecognition !== "undefined") {
      console.log("speech recognition API supported");
      console.log("typeof SpeechRecognition", typeof SpeechRecognition);
      this.recognition = new SpeechRecognition();
      this.recognition.lang = lang;
      this.recognition.continuous = true;
      this.recognition.start();
      this.contentActive = true;
      if (onaudioend !== null) {
        this.recognition.onaudioend = onaudioend;
      }
      if (onresult !== null) {
        this.recognition.onresult = onresult;
      }
      this.constructor.totalCount++;
      console.log("totalCount", this.constructor.totalCount);
    } else {
      console.log("speech recognition API not supported");
    }
  }

  stopRecognition() {
    this.recognition.stop();
  }

  static totalCount = 0;
}
