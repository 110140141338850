/* eslint-disable react/prop-types */
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import TextEdit from "./TextEdit";
import { isTextEditState } from "../../store/selectors/action_edit";
import RecognitionText from "./RecognitionText";

const RecognitionEdit = () => {
  const isTextEdit = useRecoilValue(isTextEditState);
  const [recognitionText] = useRecoilState(recognitionTextState);

  let content = null;
  content = isTextEdit ? (
    <TextEdit />
  ) : (
    <RecognitionText recognitionText={recognitionText} />
  );
  content = <>{content}</>;

  return content;
};

export default RecognitionEdit;
