import { selector } from "recoil";
import { viewTypeState } from "../atoms/view_type";

export const isSingleViewState = selector({
  key: "isSingleViewState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const check = viewType.type === "single";
    return check;
  },
});

export const isListViewState = selector({
  key: "isListViewState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const check = viewType.type === "list";
    return check;
  },
});

export const noteIdState = selector({
  key: "noteIdState",
  get: ({ get }) => {
    const viewType = get(viewTypeState);
    const res = viewType.note.id;
    return res;
  },
});
