import { atom } from "recoil";
import RecognizeNote from "../../models/RecognizeNote";

export const viewTypeState = atom({
  key: "viewTypeState",
  default: {
    type: "single",
    note: new RecognizeNote(),
  },
});
