import { langTextGet, toEnLatSmall } from "./lang_conversion";
import SpeechRecognize from "../models/SpeechRecognize";
import { ACTION_EDIT_ACTIVATION, EDIT_ACTIONS } from "./constants";

export const recognitonTextGet = (
  text,
  textLang = "latr",
  recognitionText = ""
) => {
  let res = langTextGet(text, textLang);
  res = res.toLowerCase();
  let recognText = recognitionText.trim();
  if (recognText.substring(recognText.length - 1) === ".") {
    res = res.charAt(0) + res.charAt(1).toUpperCase() + res.substring(2);
  }

  return res;
};

export const onresultFunctionGet = (setSpeechText) => (event) => {
  console.log("Audio capturing result event");
  const speechTxt = event.results[event.results.length - 1][0].transcript;
  console.log("Audio capturing result event - text", speechTxt);
  setSpeechText({ text: speechTxt });
};

export const onaudioendFunctionGet = (setSpeechRecognize) => (event) => {
  console.log("onaudioend - totalCount", SpeechRecognize.totalCount);
  setSpeechRecognize(null);
  // if (SpeechRecognize.totalCount < MAX_TOTAL_SPEECH_RECOGNIZE) {
  //   new SpeechRecognize({ onresult, onaudioend });
  // } else {
  //   console.log("max total speech recognize exausted");
  // }
};

export const activationActionGet = (text, actionInsert) => {
  const textLatEnSmall = toEnLatSmall(text).trim();
  let res = { activation: false, action: "" };
  if (actionInsert) {
    res.activation = true;
    if (EDIT_ACTIONS.includes(textLatEnSmall)) {
      res.action = textLatEnSmall;
    }
  } else {
    if (textLatEnSmall === ACTION_EDIT_ACTIVATION) {
      res.activation = true;
    } else {
      if (textLatEnSmall.indexOf(ACTION_EDIT_ACTIVATION + " ") === 0) {
        const actionPart = textLatEnSmall.substring(
          ACTION_EDIT_ACTIVATION.length + 1
        );
        if (EDIT_ACTIONS.includes(actionPart)) {
          res.activation = true;
          res.action = actionPart;
        }
      }
    }
  }

  return res;
};
