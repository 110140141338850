/* eslint-disable react/prop-types */
import React from "react";
import { useRecoilValue } from "recoil";
import { actionEditState } from "../../store/atoms/action_edit";
import styled from "@emotion/styled";

const ActionActive = () => {
  const actionEdit = useRecoilValue(actionEditState);
  let content = null;
  if (actionEdit.actionInsert) {
    content = <Div className="action-active">Action Active</Div>;
  }

  return content;
};

const Div = styled.div`
  @keyframes {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  position: absolute;
  top: 0.8rem;
  right: 1rem;
  color: gray;
  animation-name: action_active;
  animation-duration: 2s;
  animation-iteration-count: infinite;

`;

export default ActionActive;
