import React, { useState } from "react";
import { RecoilRoot } from "recoil";
import "./App.css";
import MainContent from "./layout/MainContent";
import Navigation from "./navigation/Navigation";
import ActionNotify from "./UI/ActionNotify";
import ActionConfirm from "./UI/ActionConfirm";
import MainHeader from "./layout/MainHeader";
import RecognitionProcessing from "./recognition/RecognitionProcessing";
import { DBConfig } from "../indexeddb/DBConfig";
import { initDB } from "react-indexed-db";
import { envTypeGet, apiUrlBaseGet } from "../common/common";

// if (typeof process === "undefined" || process?.env?.NODE_ENV !== "test") {
//   initDB(DBConfig);
// }
initDB(DBConfig);

console.log(
  "envTypeGet: ",
  envTypeGet(),
  "apiUrlBaseGet: ",
  apiUrlBaseGet(false)
);

fetch(apiUrlBaseGet(false) + "/api")
  .then((response) => {
    return response.text();
  })
  .then((result) => {
    console.log("result", result);
  });

function App() {
  const [diktafontNavigationAct, setDiktafontNavigationAct] = useState(false);
  const diktafontNavigationActToggle = () => {
    setDiktafontNavigationAct((currentValue) => !currentValue);
  };
  return (
    <RecoilRoot>
      <div className="diktafont-main">
        <RecognitionProcessing />
        <MainHeader
          diktafontNavigationActToggle={diktafontNavigationActToggle}
        />
        <ActionNotify />
        <ActionConfirm />
        <div className="main-content-nav">
          <div className="diktafont-content">
            <MainContent />
          </div>
          <div
            className={`diktafont-navigation ${
              diktafontNavigationAct ? "diktafont-navigation-act" : ""
            }`}
          >
            <Navigation />
          </div>
        </div>
      </div>
    </RecoilRoot>
  );
}

export default App;
