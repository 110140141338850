/* eslint-disable react/prop-types */
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import "./Navigation.css";
import Button from "@mui/material/Button";
import { actionNotifyTextState } from "../../store/atoms/action_notify_text";
import { recognitionTextState } from "../../store/atoms/recognition_text";
import { copyToClipboard } from "../../common/common";

const NavBasic = () => {
  const setActionNotifyText = useSetRecoilState(actionNotifyTextState);
  const [recognitionText, setRecognitionText] =
    useRecoilState(recognitionTextState);

  const copyRecordingClickHandle = () => {
    copyToClipboard(recognitionText);
    setActionNotifyText("Coppied to clipboard");
  };

  const newRecordingClickHandle = () => {
    setRecognitionText("");
  };

  return (
    <div
      className="navigation-main-group"
      style={{ flexDirection: "column", marginTop: 0 }}
    >
      <div
        className="navigation-main-group"
        style={{ justifyContent: "space-between", marginTop: "0rem" }}
      >
        <Button variant="contained" onClick={copyRecordingClickHandle}>
          COPY
        </Button>
        <Button
          variant="contained"
          onClick={newRecordingClickHandle}
          sx={{ marginLeft: "1rem" }}
        >
          DELETE
        </Button>
      </div>
    </div>
  );
};

export default NavBasic;
